<template>
    <div>
        <titleBar
        style="max-height: 50px;margin-bottom: 10px;"
        :username="currentUserName"
        :pageName="pageName"
        ></titleBar>
        <!-- dialog block start -->
        <div>
            <v-row>
                <v-dialog
                v-model="editOfferDialog"
                persistent :overlay="true"
                max-width="1024px"
                class="createOfferDialog"
                width="90%"
                >
                    <v-card class="py-10">
                        <v-card-title primary-title class="d-flex justify-space-between ">
                            <div>
                                <h3 class="headline mb-0">Edit Offers</h3>
                            </div>
                            <div>
                                <img @click="editOfferDialog= false" src="https://s3iconimages.mymedicine.com.mm/popupClose.svg">
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-form v-model="editFormData">
                            <div class="pa-2">
                                <div class="d-flex justify-space-between">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">Offer Name</p>
                                        </div>
                                        <div class="ml-7">
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            :disabled="editAllFlag"
                                            outline 
                                            placeholder="Offer Name(EN)" 
                                            :rules="[rules.required, rules.nameLength]"
                                            v-model="offerNameEN"></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">Offer Name(MM)</p>
                                        </div>
                                        <div class="ml-7">
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            outline 
                                            :disabled="editAllFlag"
                                            :rules="[rules.required, rules.mmNameLength]"
                                            placeholder="Offer Name(MM)" 
                                            v-model="offerNameMM"></v-text-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex align-center">
                                    <div>
                                        <p class="ma-0 text-left">Offer</p>
                                        <p class="text-left"> Description(EN)</p>
                                    </div>
                                    <div class="ml-2" style="width: 80%;">
                                        <v-textarea 
                                        rows="1" 
                                        :disabled="editAllFlag"
                                        :rules="[rules.required, rules.descriptionLength]"
                                        v-model="offerDescriptionEN"></v-textarea>
                                    </div>
                                </div>

                                <div class="d-flex align-center">
                                    <div>
                                        <p class="ma-0 text-left">Offer</p>
                                        <p class="text-left"> Description(MM)</p>
                                    </div>
                                    <div class="ml-2" style="width: 80%;">
                                        <v-textarea 
                                        rows="1" 
                                        :disabled="editAllFlag"
                                        :rules="[rules.required, rules.mmDescriptionLength]"
                                        v-model="offerDescriptionMM"></v-textarea>
                                    </div>
                                </div>

                                <div class="d-flex justify-space-between">
                                    <div class="d-flex createOffersWidth" style="margin-top:2%;">
                                        <div style="margin-top:2% ;">
                                            <p class="text-left">Offer Image</p>
                                        </div>
                                        <div class="ml-10" style="width: 100% !important;">
                                            <v-file-input 
                                            v-model="offerImage"
                                            label="Click Here To Upload Offer Banner" 
                                            prepend-icon=""
                                            :disabled="editAllFlag"
                                            variant="solo"
                                            @change="uploadBanner();"
                                            >
                                            </v-file-input>
                                        </div>
                                    </div>
                                    <div class="createOffersWidth">
                                        <img width="250px"  v-if="!imageLoading" :src=offerImagePreview>
                                        <v-progress-circular
                                        v-else
                                        indeterminate
                                        color="primary"
                                        ></v-progress-circular>
                                    </div>
                                </div>

                                <div class="d-flex  justify-space-between">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2" >
                                            <p class="text-left">Offer type</p>
                                        </div>
                                        <div class="ml-10">
                                            <v-radio-group
                                            v-model="offerType"
                                            :disabled="editAllFlag"
                                            :rules="[rules.required]"
                                            row
                                            >
                                                <v-radio
                                                label="Promotion"
                                                value="PROMOTION"
                                                class="offerTypeRadio"
                                                ></v-radio>
                                                <v-radio
                                                label="Coupon"
                                                value="COUPON"
                                                class="offerTypeRadio"
                                                ></v-radio>
                                            </v-radio-group>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth" v-if="offerType === 'COUPON'">
                                        <div class="mt-2">
                                            <p class="text-left">Coupon Code</p>
                                        </div>
                                        <div class="ml-7">
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            outline 
                                            :disabled="editAllFlag"
                                            :rules="[rules.required]"
                                            label="Enter Coupon Code" 
                                            v-model="couponCode"></v-text-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-space-between mt-4">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">Issue Quantity</p>
                                        </div>
                                        <div class="ml-4">
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            :disabled="editAllFlag"
                                            outline 
                                            style="width: 200px;"
                                            type="Number" 
                                            label="Total Issue Quantity Amount" 
                                            :rules="[rules.required]"
                                            v-model="issueQuantity"></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2" style="width: 35%;">
                                            <p class="text-left">Issue Quantity/person</p>
                                        </div>
                                        <div>
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            outline 
                                            :disabled="editAllFlag"
                                            type="Number" 
                                            label="Minimum Amount" 
                                            v-model="issueQuantityPerPerson"></v-text-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex">
                                    <div class="d-flex align-center">
                                        <div>
                                            <p class="text-left ma-0">Discount</p>
                                            <p class="text-left">Scope</p>
                                        </div>
                                        <div class="ml-10">
                                            <v-select
                                            :items="discountScopeItems"
                                            item-text="scopeText"
                                            style="width: 200px;"
                                            item-value="scopeValue"
                                            v-model="selectedDiscountScope"
                                            label="Discount scope"
                                            :disabled="editAllFlag"
                                            :rules="[rules.required]"
                                            ></v-select>
                                        </div>
                                    </div>
                                    <div class="d-flex" style="margin-left: 17% ;">
                                        <div>
                                            <v-autocomplete
                                            :search-input.sync="searchSpecialization"
                                            :disabled="editAllFlag"
                                            @input="searchSpecialization = ''"
                                            @blur="getDoctorsListForSpecializationList()"
                                            :items="listOfSpecialization"
                                            item-text = 'specialization_name'
                                            item-value="_id"
                                            chips
                                            v-model="selectedSpecialization"
                                            label="Specialization"
                                            multiple
                                            :rules="[rules.required]"
                                            ></v-autocomplete>
                                        </div>
                                        <div class="ml-4" v-if="selectedDiscountScope === 'DOCTORS'">
                                            <v-autocomplete
                                            :search-input.sync="searchDoctor"
                                            @input="searchDoctor = ''"
                                            :disabled="editAllFlag"
                                            :items="listOfDoctor"
                                            item-text="doctor_name"
                                            item-value="_id"
                                            chips
                                            v-model="selectedDoctor"
                                            multiple
                                            label="Doctors"
                                            :rules="[rules.required]"
                                        ></v-autocomplete>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-space-between ">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">User Type</p>
                                        </div>
                                        <div class="ml-10">
                                            <v-select
                                            :items="listOfUserType"
                                            item-text="userTypeText"
                                            item-value="userTypeValue"
                                            v-model="selectedUserType"
                                            label="Select User Type"
                                            :disabled="editAllFlag"
                                            style="width: 200px;"
                                            :rules="[rules.required]"
                                            ></v-select>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth" v-if="selectedUserType === 'WHITELIST'">
                                        <div class="mt-2">
                                            <p class="text-left">Whitelist Group</p>
                                        </div>
                                        <div class="ml-10">
                                            <v-select
                                            :items="listOfWhiteListedGroup"
                                            item-text="pk"
                                            v-model="selectedWhitelistedGroup"
                                            label="Select Whitelist Group"
                                            ></v-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-space-between">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">Discount Type</p>
                                        </div>
                                        <div class="ml-4">
                                            <v-select
                                            dense
                                            :items="typeOfDiscount"
                                            item-text="offerText"
                                            :disabled="editAllFlag"
                                            item-value="offerTypeValue"
                                            v-model="selectedDiscountType"
                                            label="Select Offer Type"
                                            :rules="[rules.required]"
                                            ></v-select>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2" style="width: 35%;">
                                            <p class="text-left">Discount Value</p>
                                        </div>
                                        <div v-if="selectedDiscountType == 'PERCENTAGE_OFF'">
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            :disabled="editAllFlag"
                                            outline 
                                            label="Discount Amount" 
                                            :rules= "[rules.percentLimit, rules.required]"
                                            v-model="discountValue"></v-text-field>
                                        </div>
                                        <div v-else>
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            :disabled="editAllFlag"
                                            outline 
                                            label="Discount Amount" 
                                            :rules= "[rules.required]"
                                            v-model="discountValue"></v-text-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-space-between mt-4">
                                    <div class="d-flex align-center createOffersWidth" v-if="selectedDiscountType == 'PERCENTAGE_OFF'">
                                        <div class="mt-2">
                                            <p class="text-left">Maximum Amount</p>
                                        </div>
                                        <div class="ml-4">
                                            <v-text-field 
                                            class="ma-0" 
                                            dense
                                            :disabled="editAllFlag"
                                            :rules= "[rules.required]" 
                                            outline 
                                            type="Number" 
                                            style="width: 200px;"
                                            label="Maximum Amount" 
                                            v-model="maximumDiscountAmount"></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2" style="width: 35%;">
                                            <p class="text-left">Minimum spent</p>
                                        </div>
                                        <div>
                                            <v-text-field 
                                            :rules= "[rules.required]"
                                            class="ma-0" 
                                            dense 
                                            outline 
                                            :disabled="editAllFlag"
                                            type="Number" 
                                            label="Minimum Amount" 
                                            v-model="minimumDiscountAmount"></v-text-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-space-between mt-4">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">Start Date</p>
                                        </div>
                                        <div class="ml-10">
                                            <v-menu 
                                            ref="startDateMenu" 
                                            v-model="startDateMenu" 
                                            :close-on-content-click="false" 
                                            transition="scale-transition" 
                                            :disabled="startDateEditFlag"
                                            offset-y min-width="auto" >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field 
                                                    v-model="offerStartDate" 
                                                    readonly
                                                    :disabled="startDateEditFlag"
                                                    :rules= "[rules.required]" 
                                                    label="Offer Start Date" 
                                                    background-color="#F4F6FA"  
                                                    v-bind="attrs" 
                                                    v-on="on" ></v-text-field>
                                                </template>
                                                <v-date-picker 
                                                style="padding:20px; height: 500px;color: black" 
                                                v-model="offerStartDate" 
                                                show-adjacent-months 
                                                :disabled="startDateEditFlag"
                                                :allowed-dates="allowedDates"></v-date-picker>
                                            </v-menu>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">Start Time</p>
                                        </div>
                                        <div>
                                        <v-text-field
                                        v-model="startTime"
                                        label="Current  Start Time"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        disabled
                                        :rules= "[rules.required]"
                                        ></v-text-field>
                                        </div>
                                        <div class="ml-10">
                                            <v-menu
                                                ref="startTimeMenu"
                                                v-model="startTimeMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :disabled="startDateEditFlag"
                                                :return-value.sync="startTime"
                                                transition="scale-transition"
                                                offset-y
                                                :rules= "[rules.required]" 
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="editedStartTime"
                                                    label="New Offer Start Time"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    :disabled="startDateEditFlag"
                                                    :rules= "[rules.required]" 
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                v-if="startTimeMenu"
                                                v-model="editedStartTime"
                                                :disabled="startDateEditFlag"
                                                full-width
                                                @click:minute="$refs.startTimeMenu.save(editedStartTime)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">End Date</p>
                                        </div>
                                        <div class="ml-10">
                                            <v-menu ref="endDateMenu" v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field 
                                                    :rules= "[rules.required]"
                                                    v-model="offerEndDate" 
                                                    :disabled="endDateEditFlag"
                                                    readonly 
                                                    label="Offer End Date" 
                                                    background-color="#F4F6FA"  
                                                    v-bind="attrs" 
                                                    v-on="on" ></v-text-field>
                                                </template>
                                                <v-date-picker style="padding:20px; height: 500px;color: black" v-model="offerEndDate" show-adjacent-months :allowed-dates="allowedDates"></v-date-picker>
                                            </v-menu>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">End Time</p>
                                        </div>
                                        <div>
                                            <v-text-field
                                            v-model="endTime"
                                            label="Current End Time"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly
                                            disabled
                                            :rules= "[rules.required]"
                                            ></v-text-field>
                                        </div>
                                        <div class="ml-10">
                                            <v-menu
                                                ref="endTimeMenu"
                                                v-model="endTimeMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :disabled="endDateEditFlag"
                                                :rules= "[rules.required]" 
                                                :return-value.sync="endTimeMenu"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="editedEndTime"
                                                    label="New Offer End Time"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    :disabled="endDateEditFlag"
                                                    :rules= "[rules.required]" 
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                v-if="endTimeMenu"
                                                v-model="editedEndTime"
                                                full-width
                                                :disabled="endDateEditFlag"
                                                @click:minute="$refs.endTimeMenu.save(editedEndTime)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="d-flex flex-row-reverse mr-10">
                            <v-btn color="primary" width="20%" :disabled="isOfferValid()" @click="editOffer()" style="background-color: #1467BF;">Save </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- dialog block end -->
        <v-app>
            <v-row cols="12">
                <v-col cols="2">
                    <sideNavBar :pageName="pageName"></sideNavBar>
                </v-col>
                <v-col cols="10" style="overflow:scroll; height:93vh">
                    <div style="margin: 20px 20px 20px 50px ">
                        <div class="d-flex justify-lg-space-between">
                            <div style="width: 30%" align="left">
                                <span  style="font-weight: 800;  font-size: 12pt;text-transform: uppercase;   letter-spacing: 1px;" class="pl-2">
                                <h3>View Offer </h3>
                                </span>
                            </div>
                            <div class="d-flex ma-5">
                                <div class="mr-2">
                                    <v-btn 
                                    class="primary" 
                                    @click=" 
                                    editOfferPage(), checkEditableFields()" 
                                    :loading="isEditOfferDisabled"
                                    v-if="!loadingOfOfferData"
                                    :disabled="issueQuantity === tableValue.length"
                                    style="background-color: #1467BF;"
                                    >
                                    Edit Offer</v-btn>
                                </div>
                                <div class="mr-2">
                                    <v-btn 
                                    class="primary" 
                                    @click=" downloadReport()" 
                                    :loading="isDownloadInitaited"
                                    v-if="!loadingOfOfferData"
                                    style="background-color: #1467BF;"
                                    >
                                    Get Report</v-btn>
                                </div>
                            </div>
                        </div>
                        <div v-if="!loadingOfOfferData">
                            <v-card>
                                <v-card-text>
                                    <div class="d-flex justify-space-between">
                                        <div>
                                            <p> Offer Name(en):  <span class="text-left offerDetailsValue">{{ offerNameEN }}</span> </p>
                                        </div>
                                        <div>
                                            <p> Offer Name(mm):  <span class="text-left offerDetailsValue">{{ offerNameMM }}</span></p>
                                        </div>
                                        <div>
                                            <p> Start Date:  <span class="text-left offerDetailsValue">{{ displayStartDateTime }}</span></p>
                                        </div>
                                        <div>
                                            <p> End Date:  <span class="text-left offerDetailsValue">{{ displayEndDateTime }}</span></p>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="d-flex" style="width: 50%;">
                                            <p style="font-size:12px ;" class="text-left">Offer Description(en):</p>
                                            <p class="text-left ml-1 offerDetailsValue">{{ offerDescriptionEN }}</p>
                                        </div>
                                        <div class="d-flex" style="width: 50%;">
                                            <p style="font-size:12px ;" class="text-left">Offer Description(mm):</p>
                                            <p class="text-left ml-1 offerDetailsValue">{{ offerDescriptionMM }}</p>
                                        </div>
                                    </div>
                                    <div class="pa-0">
                                        <v-expansion-panels 
                                        v-model="offerDetailsPanel"
                                        multiple
                                        flat
                                        >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="offerDetailsValue">Offer Details</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <div class="d-flex justify-space-between">
                                                    <div>
                                                        <p> Offer Type:  <span class="text-left offerDetailsValue">{{ offerType }}</span> </p>
                                                    </div>
                                                    <div v-if="this.offerType == 'COUPON'">
                                                        <p>  Coupon Code:  <span class="text-left offerDetailsValue">{{ couponCode }}</span> </p>
                                                    </div>
                                                    <div>
                                                        <p> Discount Type:  <span class="text-left offerDetailsValue">{{ selectedDiscountType }}</span></p>
                                                    </div>
                                                    <div>
                                                        <p> Discount value  <span class="text-left offerDetailsValue">{{ discountValue }}</span></p>
                                                    </div>
                                                    <div>
                                                        <p> Issue Quantity  <span class="text-left offerDetailsValue">{{ issueQuantity }}</span></p>
                                                    </div>
                                                    <div>
                                                        <p> Issue Quantity Per Person <span class="text-left offerDetailsValue">{{ issueQuantityPerPerson }}</span></p>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-space-between">
                                                    <div>
                                                        <p> Minimum Spent:  <span class="text-left offerDetailsValue">{{ minimumDiscountAmount }}</span> </p>
                                                    </div>
                                                    <div>
                                                        <p> Maximum Discount:  <span class="text-left offerDetailsValue">{{ maximumDiscountAmount }}</span></p>
                                                    </div>
                                                    <div>
                                                        <p>  User Type <span class="text-left offerDetailsValue">{{ selectedUserType }}</span></p>
                                                    </div>
                                                    <div>
                                                        <p> Discount Scope <span class="text-left offerDetailsValue">{{ selectedDiscountScope }}</span></p>
                                                    </div>
                                                </div>
                                                <div style="width:50%">
                                                    <v-select 
                                                    v-if="selectedDiscountScope === 'DOCTORS'"
                                                    :items="listOfSelectedScope"
                                                    item-text="doctor_name"
                                                    label="List of selected Specialization/Doctor"
                                                    item-disabled="disabled"
                                                    dense
                                                    >
                                                    </v-select>
                                                    <v-select 
                                                    v-else
                                                    :items="listOfSelectedScope"
                                                    item-text="specialization_name"
                                                    label="List of selected Specialization/Doctor"
                                                    item-disabled="disabled"
                                                    dense
                                                    >
                                                    </v-select>
                                                </div>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="offerDetailsValue">Offer Image</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <img  width="300px" :src=offerImageURL>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                    </div>
                                </v-card-text>
                            </v-card>
                            <div class="mt-5">
                                <div class="mb-2">
                                    <span  style="font-weight: 800;  font-size: 12pt;text-transform: uppercase;   letter-spacing: 1px;" class="pl-2 text-left">
                                        <h4> Claimed Benefits </h4>
                                    </span>
                                </div>
                                <tableComponent
                                :tableTitle="tableHeader" 
                                :tableItem = "tableValue"  
                                :customeSlotName = "customeSlotName"
                                :isDataLoading = 'isDataLoading'
                                :endOfDataObject = 'isEndOfData'>
                                </tableComponent>
                            </div>
                        </div>
                        <div v-else>
                            <v-progress-circular
                            indeterminate
                            color="primary"
                            ></v-progress-circular>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-app>
    </div>
</template>
<script>
import axios from 'axios';
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';

export default {
    name: 'ViewOffer',
    components: {
        titleBar: () => import('../../navbar.vue'),
        sideNavBar: () => import('../../sideBar.vue'),
        tableComponent: () => import('../../../components/tableComponent.vue')
    },
    data(){
        return{
            pageName: 'viewOffer',
            editOfferDialog: false,
            offerCode:'',
            offerCreatedAt: null,
            offerStatus:'',
            offerDetails:'',
            displayStartDateTime:'',
            displayEndDateTime:'',
            loadingOfOfferData: true,
            listOfSelectedScope:[],
            couponCode:'',
            offerNameEN: '',
            offerNameMM:'',
            editFormData: false,
            offerDescriptionEN:'',
            offerDescriptionMM:'',
            offerStartDate: '',
            startDateMenu: false,
            startTimeMenu: false,
            startTime: '',
            editedStartTime:'',
            offerEndDate: '',
            endDateMenu: false,
            endTimeMenu: false,
            endTime: '',
            editedEndTime:'',
            offerType:'', //Promo or coupon
            typeOfDiscount:[{offerText:'Percentage Off', offerTypeValue: 'PERCENTAGE_OFF'}, {offerText:'Flat Off', offerTypeValue: 'FLAT_OFF'}],
            selectedDiscountType:'',
            discountValue: '',
            maximumDiscountAmount: null,
            minimumDiscountAmount:null,
            issueQuantity:'',
            issueQuantityPerPerson:'',
            searchSpecialization:'',
            listOfSpecialization:[],
            selectedSpecialization:[],
            searchDoctor:'',
            listOfDoctor:[],
            selectedDoctor:[],
            listOfUserType:[{userTypeText:'All', userTypeValue:'ALL'}, {userTypeText:'New', userTypeValue:'NEW'},{userTypeText:'Existing', userTypeValue:'EXISTING'},{userTypeText:'Whitelisted', userTypeValue:'WHITELIST'}],
            selectedUserType: '',
            discountScopeItems:[{scopeText:'Doctors', scopeValue:'DOCTORS'}, {scopeText:'Specialization', scopeValue:'SPECIALIZATIONS'}],
            selectedDiscountScope:'',
            offerImage:[],
            offerImagePreview:'',
            offerImageName:'',
            offerImageURL:'',
            offerImageB64: '',
            offerStatus:'',
            currentUser:'',
            currentUserName:'',
            currentUserId:'',
            offerDetailsPanel:[],
            editAllFlag: true,
            startDateEditFlag: true,
            endDateEditFlag: true,
            isEditOfferDisabled: false,
            isDownloadInitaited: false,
            rules:{
                required: (value) => !!value|| 'This Field is Required',
                nameLength: (value) => (value && value.length < 120) || '120 Charater Max',
                mmNameLength: (value) => value.length <=140 || 'Name is to large',
                descriptionLength: (value) => value.length <= 120 || '120 Charater Max',
                mmDescriptionLength: value => value.length <= 120 || '120 Charater Max',
                percentLimit: value => (value <= 100) || 'Percentage should not be more than 100',
                percentMaxDiscount: value => !!value || 'Percentage discount needs Max dicount value'
            },
            imageLoading: false, 
            tableHeader:[],
            tableValue:[],
            customeSlotName:[],
            isDataLoading: false,
            isEndOfData: false,
            claimedBenefits:[],
            fileTypes: ["image/jpeg", "image/png"],
            isButtonClicked: false
        }
    },
    mounted(){
        this.currentUser = this.$cookies.get("token");
        if (!this.currentUser) {
            this.$router.push({
                name: "AdminLogin",
            });
        } else {
            var getAdminBody = {
                token : this.currentUser,
                typeOfUser : "ADMIN",
                adminType: "ADMIN"
            };
            axios_auth_instance_admin.post("/getAdmin", getAdminBody)
            .then((getAdminResponse) => {
                this.currentUserName = getAdminResponse.data.data.admin_name;
                this.currentUserId = getAdminResponse.data.data._id;
                this.viewOffer();
            })
            .catch((getAdminError) => {
                handleError(getAdminError, this.$router, 'getAdmin', 'AdminLogin', 'token');
            });
        }
    },
    methods:{
        async uploadBanner() {
            if (this.offerImage) {
                try {
                    this.offerImagePreview = URL.createObjectURL(this.offerImage);
                    this.offerImageB64 =  await new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(this.offerImage);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = (error) => reject(error);
                    });
                } catch (Exception) {
                    this.offerImagePreview = null;
                }
            } else {
                this.offerImagePreview = null;
                this.offerImageB64 = '';
            }
        },
        editOffer(){
            this.isButtonClicked = true;
            let nowDate = Date.now();
            let editedData = {};
            let currentDate = new Date(nowDate).toJSON().split('.')[0].split('T')[0];
            if(this.offerStatus != 'ARCHIVE'){
                if (this.offerStatus == 'DRAFT'){
                     editedData = {
                        'offer_name': this.offerNameEN,
                        'offer_name_mm': this.offerNameMM,
                        'offer_image': this.offerImageB64 ? this.offerImageB64 : '',
                        'description': this.offerDescriptionEN,
                        'description_mm': this.offerDescriptionMM,
                        'offer_type': this.offerType,
                        'user_type': this.selectedUserType,
                        'whitelist_name': '',
                        'discount_scope': this.selectedDiscountScope,
                        'discount_type': this.selectedDiscountType,
                        'discount': this.discountValue,
                        'issue_quantity': this.issueQuantity,
                        'redemptions_per_user': this.issueQuantityPerPerson,
                        'minimum_spend': this.minimumDiscountAmount,
                        'maximum_discount': this.maximumDiscountAmount,
                        'scope_list': this.selectedDiscountScope === "DOCTORS" ? this.selectedDoctor : this.selectedSpecialization,
                    }
                    if(this.offerType == 'COUPON') {
                        editedData['offer_code'] = this.couponCode;
                    }

                    if(this.editedStartTime && this.editedStartTime != this.startTime) {
                        editedData['start_date'] = this.offerStartDate + ' ' + this.editedStartTime +':00';
                    } else {
                        editedData['start_date'] = this.offerStartDate + ' ' + this.startTime +':00';
                    }

                    if(this.editedEndTime && this.editedEndTime != this.endTime) {
                        editedData['end_date'] = this.offerEndDate + ' ' + this.editedEndTime +':00';
                    } else {
                        editedData['end_date'] = this.offerEndDate + ' ' + this.endTime +':00';
                    }
                } else if (this.offerStatus == 'ACTIVE' && this.offerStartDate > currentDate){
                     editedData = {};
                     if(this.editedStartTime && this.editedStartTime != this.startTime) {
                        editedData['start_date'] = this.offerStartDate + ' ' + this.editedStartTime +':00';
                    } else {
                        editedData['start_date'] = this.offerStartDate + ' ' + this.startTime +':00';
                    }

                    if(this.editedEndTime && this.editedEndTime != this.endTime) {
                        editedData['end_date'] = this.offerEndDate + ' ' + this.editedEndTime +':00';
                    } else {
                        editedData['end_date'] = this.offerEndDate + ' ' + this.endTime +':00';
                    }
                } else {
                     editedData = {};
                     if(this.editedEndTime && this.editedEndTime != this.endTime) {
                        editedData['end_date'] = this.offerEndDate + ' ' + this.editedEndTime +':00';
                    } else {
                        editedData['end_date'] = this.offerEndDate + ' ' + this.endTime +':00';
                    }
                }
            } else {
                alert('Archive Offers cannot be edited')
            }
            let editOfferBody = {
                token: this.currentUser,
                typeOfUser: 'ADMIN',
                offerCode: this.offerCode,
                status: this.offerStatus,
                editData: JSON.stringify(editedData)
            }
            axios_auth_instance_admin.patch('/editOffer', editOfferBody)
            .then((editOfferResponse) => {
                this.editOfferDialog = false;
                alert(`${ this.offerNameEN } had been edited Successfully !!`);
                this.editOfferDialog = false;
                this.isButtonClicked = false;
                this.$router.go();
            })
            .catch((editOfferError) => {
                alert(`${ this.offerNameEN } edit failed!! - ${ editOfferError.response.data.data }`);
                handleError(editOfferError, this.$router, 'editOffer', 'AdminLogin', 'token');
                this.editOfferDialog =false;
                this.isButtonClicked = false;
            });
        },
        allowedDates(val)
        {
            return val >= new Date().toISOString().split("T")[0] 
        },
        viewOffer(){
            var params = this.$route.params;
            this.offerCode = params['offerName'];
            this.offerCreatedAt = params['createdAt'];
            this.offerStatus = params['offerStatus'];
            let viewParticularOfferPageBody = {};
            if (this.offerStatus.toUpperCase() !== 'ARCHIVE') {
                viewParticularOfferPageBody = {
                    token: this.currentUser,
                    typeOfUser:'ADMIN',
                    offerCode: this.offerCode,
                    status: this.offerStatus.toUpperCase()
                };
            } else {
                viewParticularOfferPageBody = {
                    token: this.currentUser,
                    typeOfUser:'ADMIN',
                    offerCode: this.offerCode,
                    createdAt: this.offerCreatedAt,
                    status: this.offerStatus.toUpperCase()
                };
            }
            axios_auth_instance_admin.get('/viewParticularOfferPage', {params: viewParticularOfferPageBody})
            .then((viewParticularOfferPageResponse) => {
                // let dateNow = new Date();
                this.offerDetails = viewParticularOfferPageResponse.data.data.offer_data;
                this.offerNameEN = this.offerDetails.offer_name;
                this.offerNameMM = this.offerDetails.offer_name_mm;
                this.offerDescriptionEN = this.offerDetails.description;
                this.offerDescriptionMM = this.offerDetails.description_mm;
                this.offerStartDate = this.offerDetails.start_date.split(' ')[0],
                this.startTime = this.offerDetails.start_date.split(' ')[1].slice(0, 5),
                this.displayStartDateTime = this.offerDetails.start_date;
                this.offerEndDate = this.offerDetails.end_date.split(' ')[0],
                this.endTime = this.offerDetails.end_date.split(' ')[1].slice(0, 5),
                this.displayEndDateTime = this.offerDetails.end_date;
                this.offerType = this.offerDetails.offer_type;
                this.selectedDiscountScope = this.offerDetails.discount_scope;
                this.selectedDiscountType = this.offerDetails.discount_type;
                this.discountValue = this.offerDetails.discount;
                this.issueQuantity = this.offerDetails.issue_quantity
                this.issueQuantityPerPerson = this.offerDetails.redemptions_per_user; //issueQuantityPerPerson need to be assingned 
                this.maximumDiscountAmount = this.offerDetails.maximum_discount;
                this.minimumDiscountAmount = this.offerDetails.minimum_spend;
                this.selectedUserType = this.offerDetails.user_type;
                this.listOfSelectedScope = viewParticularOfferPageResponse.data.data.offer_scope_list;
                this.listOfSelectedScope.forEach(selectedScope => {
                    selectedScope['disabled'] = true;
                });
                this.loadingOfOfferData = false;
                this.offerImageURL = this.offerDetails.offer_image;
                if(this.offerType === 'COUPON') {
                    this.couponCode = this.offerDetails.offer_code;
                };
                this.offerImagePreview = this.offerImageURL;
                this.tableHeader = [
                    { text: 'Appointment Reff No.', value: 'appointment_ref_name', sortable: false },
                    { text: 'Appointment Status', value: 'appointment_status', sortable: false },
                    { text: 'Customer Name', value: 'customer_name', sortable: false },
                    { text: 'Doctor Name', value: 'doctor_name', sortable: false },
                    { text: 'Specialization Name', value: 'specialization_name', sortable: false },
                    { text: 'Date Of Appointment', value: 'appointment_date', sortable: false }
                ];
                this.claimedBenefits = viewParticularOfferPageResponse.data.data.claimed_appointments;
                this.claimedBenefits.forEach( benefitData => {
                    this.tableValue.push({
                        'appointment_ref_name': benefitData.appointment_reference_number,
                        'appointment_status': benefitData.status,
                        'customer_name': benefitData.booked_for_name,
                        'doctor_name': benefitData.doctor_name,
                        'specialization_name': benefitData.specialization_name,
                        'appointment_date': benefitData.date_of_appointment
                    });
                });
            })
            .catch((viewParticularOfferPageError) => {
                this.loadingOfOfferData = false;
                handleError(viewParticularOfferPageError, this.$router, 'viewParticularOfferPage', 'AdminLogin', 'token');
            })
        },
        editOfferPage(){
            this.isEditOfferDisabled = true;
            let editOfferPageBody = {
                token: this.currentUser,
                typeOfUser: 'ADMIN',
                offerCode: this.offerCode
            };
            axios_auth_instance_admin.get('/editOfferPage', { params: editOfferPageBody })
            .then((editOfferPageResponse) => { 
                this.selectedSpecialization = [];
                this.selectedDoctor = [];
                this.listOfSpecialization = editOfferPageResponse.data.specializations_data;
                if (this.selectedDiscountScope == 'SPECIALIZATIONS') {
                    let applicableList = editOfferPageResponse.data.applicable_list
                    applicableList.forEach(specializationData => {
                        this.selectedSpecialization.push(specializationData._id)
                    });
                } else if (this.selectedDiscountScope == 'DOCTORS') {
                    let applicableList = editOfferPageResponse.data.applicable_list
                    applicableList.forEach(doctorData => {
                        this.listOfDoctor.push(doctorData)
                        this.selectedDoctor.push(doctorData._id)
                        if(!this.selectedSpecialization.includes(doctorData.specialist[0]._id)){
                            this.selectedSpecialization.push(doctorData.specialist[0]._id)
                        }
                    });
                }
                this.isEditOfferDisabled = false;
                this.editOfferDialog=true;
            })
            .catch((editOfferPageError) => { 
                this.isEditOfferDisabled = false;
                handleError(editOfferPageError, this.$router,'editOfferPage', 'AdminLogin', 'token');
            })
        },
        getDoctorsListForSpecializationList(){
            if (this.selectedSpecialization.length > 0){
                this.loadingDoctorList = true;
                var getDoctorsBySpecializationListBody = {
                    token : this.currentUser,
                    typeOfUser: 'ADMIN',
                    specializationList: JSON.stringify(this.selectedSpecialization)
                }
                axios_auth_instance_admin.get('/getDoctorsBySpecializationList',{params : getDoctorsBySpecializationListBody})
                .then((getDoctorsBySpecializationListResponse) => {
                    if(this.selectedDoctor.length != 0) {
                        this.listOfDoctor = [];
                        let oldSelectedDoctorList = this.selectedDoctor;
                        this.selectedDoctor = [];
                        this.listOfDoctor = getDoctorsBySpecializationListResponse.data.data;
                            this.selectedSpecialization.forEach((specId) => {
                                let eligibleDoctor = this.listOfDoctor.find((element) => {
                                    return element.specialist[0]._id == specId && oldSelectedDoctorList.includes(element._id);
                                })
                            this.selectedDoctor.push(eligibleDoctor._id);
                            this.loadingDoctorList = false;
                        })
                    } else {
                        this.listOfDoctor = getDoctorsBySpecializationListResponse.data.data;
                        this.loadingDoctorList = false;
                    } 
                })
                .catch((getDoctorsBySpecializationListError) => {
                    handleError(getDoctorsBySpecializationListError, this.$router,'getDoctorsBySpecializationList', 'AdminLogin', 'token');
                })
            }
        },
        checkEditableFields(){
            let nowDate = Date.now();
            let currentDate = new Date(nowDate).toJSON().split('.')[0].split('T')[0];
            if(this.offerStatus != 'ARCHIVE'){
                if (this.offerStatus == 'DRAFT'){
                    this.editAllFlag = false;
                    this.startDateEditFlag = false;
                    this.endDateEditFlag = false;
                } else if (this.offerStatus == 'ACTIVE' && this.offerStartDate > currentDate){
                    this.editAllFlag = true;
                    this.startDateEditFlag = false;
                    this.endDateEditFlag = false;
                } else {
                    this.endDateEditFlag = false;
                    this.editAllFlag = true;
                    this.startDateEditFlag = true;
                }
            } else {
                alert('Archive Offers cannot be edited')
            }
        },
        isOfferValid(){
            if (parseInt(this.minimumDiscountAmount) >= 0) {
                this.editFormData = true;
            }
            return (this.isButtonClicked || !(this.offerNameEN && this.offerNameMM && this.editFormData  && this.offerDescriptionEN  && this.offerDescriptionMM  && this.offerType && this.selectedUserType && this.selectedDiscountType && this.selectedDiscountScope  && this.offerStartDate && this.startTime && this.offerEndDate && this.endTime && parseInt(this.issueQuantity) > 0 && parseInt(this.issueQuantityPerPerson) > 0 && ((parseInt(this.issueQuantity) == 1 && parseInt(this.issueQuantityPerPerson) == 1) || (parseInt(this.issueQuantityPerPerson) < parseInt(this.issueQuantity))) && ((this.selectedDiscountScope === 'DOCTORS' && this.selectedDoctor) || (this.selectedDiscountScope === 'SPECIALIZATIONS' && this.selectedSpecialization)) && parseInt(this.discountValue) > 0 && ((this.selectedDiscountType == 'FLAT_OFF' && parseInt(this.minimumDiscountAmount) >= 0) || (this.selectedDiscountType == 'PERCENTAGE_OFF' && parseInt(this.discountValue) <= 100 && parseInt(this.maximumDiscountAmount) >= 0))) || this.isCreateButtonClicked);
        },
        downloadReport() {
            this.isDownloadInitaited = true;
            var downloadReportBody = {
                token: this.currentUser,
                typeOfUser: 'ADMIN',
                offerCode: this.offerCode,
                offerStatus: this.offerStatus,
                createdAt: this.offerCreatedAt
            }
            axios_auth_instance_admin.get('/downloadOfferReport', { params: downloadReportBody})
            .then((downloadOfferReportReponse) => {
                alert(` ${ this.offerNameEN } report has been mailed to ${ this.currentUserName } Email !`);
                this.isDownloadInitaited= false;
            })
            .catch((downloadReportError) => {
                alert(` ${ this.offerNameEN } report generation failed!`);
                this.isDownloadInitaited = false;
                handleError(downloadReportError, this.$router, 'downloadOfferReport', 'AdminLogin', 'token');
            })
        }
        }
    }
</script>
<style scoped>
.offerDetailsValue{
    color: black;
    font-weight: 600;
}
.createOffersWidth{
    width: 50%;
}
.py-10 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pa-2 {
  padding: 8px;
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.mt-2 {
  margin-top: 8px;
}

.text-left {
  text-align: left;
}

.ml-7 {
  margin-left: 28px;
}

.ma-0 {
  margin: 0;
}

.ml-10 {
  margin-left: 40px;
}

.ml-4 {
  margin-left: 16px;
}

</style>
<style>
.offerTypeRadio .v-icon.v-icon{
    font-size: 12px !important;
}
.v-list.v-select-list.v-sheet.theme--light.v-list--dense.theme--light {
    max-height: 200px !important;
}
</style>